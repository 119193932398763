import { getData, deleteData, postData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchSystems = () => {
  const { data: systems, isLoading: systemsLoading } = useQuery({
    queryKey: ["gallery"],
    queryFn: () => getData(`galleries`),
  });
  return { systems, systemsLoading };
};

export const useDeleteGallery = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteGalleryPending, mutate: deleteGallery } =
    useMutation({
      mutationFn: (itemId) => {
        return deleteData(`gallery/delete/${itemId}`, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["gallery"] });
        close();
      },
    });

  return { deleteGallery, deleteGalleryPending };
};

export const useChangeStatus = () => {
  const queryClient = useQueryClient();
  const { isPending: changeStatusPending, mutate: changeStatus } = useMutation({
    mutationFn: (itemId) => {
      return postData(`gallery/change-status`, { id: itemId }, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["gallery"] });
    },
  });

  return { changeStatus, changeStatusPending };
};
