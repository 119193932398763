import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { FileUp } from "lucide-react";

export const CustomFileInput = ({
  label,
  touched,
  error,
  name,
  className,
  placeholder,
  onChange,
  value,
  labelClassName,
  multiple,
}) => {
  const displayValue = Array.isArray(value)
    ? value.map((file) => file.name).join(", ") // Display multiple file names
    : value instanceof File
    ? value.name
    : value === ""
    ? placeholder
    : value;

  return (
    <div className="grid gap-2">
      {label ? (
        <Label
          className={cn("flex justify-between items-center", labelClassName)}
          htmlFor={name}
        >
          <span>{label}</span>

          {touched && error ? (
            <span className="font-normal text-xs text-red-500 leading-none">
              {error}
            </span>
          ) : null}
        </Label>
      ) : null}

      <label
        className={cn(
          "flex items-center justify-between h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm",
          value instanceof File || Array.isArray(value)
            ? "text-foreground"
            : "text-muted-foreground",
          className
        )}
      >
        <span>{displayValue || placeholder}</span>
        <FileUp className="w-5 h-5" />
        <input
          hidden
          type="file"
          name={name}
          id={name}
          onChange={(e) => {
            const files = e.target.files;
            if (files) {
              onChange(multiple ? Array.from(files) : files[0]);
            } else {
              onChange(null);
            }
          }}
          multiple={multiple}
        />
      </label>
    </div>
  );
};
