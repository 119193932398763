import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { cn } from "lib/utils";
import { Label } from "components/ui/label";

export function CustomSelect({
  error,
  touched,
  label,
  value,
  onChange,
  options,
  className,
  placeholder,
  name,
  noOptionsMessage,
  labelClassName,
}) {
  return (
    <div className="w-full grid gap-2">
      {label ? (
        <Label
          className={cn("flex justify-between", labelClassName)}
          htmlFor={name}
        >
          {label}

          {touched && error ? (
            <span className="font-normal text-xs text-red-500 leading-none">
              {error}
            </span>
          ) : null}
        </Label>
      ) : null}

      <Select
        value={value}
        name={name}
        onValueChange={(value) => onChange(value)}
      >
        <SelectTrigger
          className={cn(
            className,
            "flex items-center max-w-full whitespace-nowrap overflow-hidden text-ellipsis"
          )}
        >
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent className="bg-white z-[1111]">
          <SelectGroup>
            {options && options.length > 0 ? (
              options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))
            ) : (
              <div className="p-2 text-sm text-gray-500 text-center">
                {noOptionsMessage || "Ma'lumotlar topilmadi"}
              </div>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
