import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { getBase64, postData, updateData, getData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddUpdateMaqola = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionMaqolaPending, mutate: addUpdateMaqola } =
    useMutation({
      mutationFn: async (data) => {
        let imageBase64 = data?.image;

        if (imageBase64 instanceof File) {
          imageBase64 = await getBase64(imageBase64);
        }

        const sendingData = {
          post_menu_id: data.type,
          post_image: imageBase64,
          post_date: data.date,
          file_name: data?.image?.name || "",

          post_content: selectedItem?.post_content || "",
          post_content_ru: selectedItem?.post_content_ru || "",
          post_content_en: selectedItem?.post_content_en || "",

          post_desc: selectedItem?.post_desc || "",
          post_desc_ru: selectedItem?.post_desc_ru || "",
          post_desc_en: selectedItem?.post_desc_en || "",

          post_title: selectedItem?.post_title || "",
          post_title_ru: selectedItem?.post_title_ru || "",
          post_title_en: selectedItem?.post_title_en || "",
        };

        if (lang === "uz") {
          sendingData.post_content = data.content;
          sendingData.post_desc = data.desc;
          sendingData.post_title = data.title;
        } else if (lang === "ru") {
          sendingData.post_content_ru = data.content;
          sendingData.post_desc_ru = data.desc;
          sendingData.post_title_ru = data.title;
        } else if (lang === "en") {
          sendingData.post_content_en = data.content;
          sendingData.post_desc_en = data.desc;
          sendingData.post_title_en = data.title;
        }

        if (selectedItem) {
          return updateData(
            `edit-post/${selectedItem?.post_id}`,
            sendingData,
            true
          );
        }
        return postData("create-post", sendingData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["posts"] });
        close();
      },
    });

  return {
    actionMaqolaPending,
    addUpdateMaqola,
  };
};

export const useFetchPostTypesList = () => {
  const { data: types, isLoading: typesLoading } = useQuery({
    queryKey: ["maqola-types"],
    queryFn: () => getData("sub-menu-type/10"),
  });

  return { types, typesLoading };
};
