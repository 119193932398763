import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchKafedraList = (currentPage) => {
  const { data: kafedraList, isLoading: postsKafedraLoading } = useQuery({
    queryKey: ["kafedralar"],
    queryFn: () => getData(`list-kafedra`),
  });

  return { kafedraList, postsKafedraLoading };
};

export const useDeleteKafedra = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteKafedraPending, mutate: deleteKafedra } =
    useMutation({
      mutationFn: (itemId) => {
        return deleteData(`delete-kafedra/${itemId}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["kafedralar"] });
        close();
      },
    });

  return { deleteKafedra, deleteKafedraPending };
};
