import React, { useEffect, useState } from "react";
import { useApiRequest } from "../../hooks/useRequest";
import { FiArrowUpCircle } from "react-icons/fi";
import Tabs from "../../components/NewsTabs";
import StrategyBanner from "images/strategy_banner.png";
import { LuSquareArrowUpRight } from "react-icons/lu";
import PartnersCarousel from "../../components/PartnersCarousel";
import GaleryTabs from "../../components/GaleryTabs";
import Foydali from "../../components/Foydali";
import Header from "../../components/HeaderMain";
import Menu from "../../features/Menu/MenuMain";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
// import video from "video/vide_bg.mp4";

function HomePage() {
  const { postRequest, loading, getRequest } = useApiRequest();
  const [kafedralar, setKafedraList] = useState([]);
  const [statistikalar, setStatistikaList] = useState([]);
  const [services, setServicesList] = useState([]);
  const [systems, setSystemList] = useState([]);

  useEffect(() => {
    GetData();
    GetStatistics();
    GetServices();
    GetSystems();
  }, []);

  const GetData = async () => {
    const response = await getRequest("list-kafedra", {}, false);
    if (response) {
      setKafedraList(response?.data);
    }
  };
  const GetStatistics = async () => {
    const response = await getRequest("statistics", { status: true }, false);
    if (response) {
      setStatistikaList(response?.data);
    }
  };
  const GetServices = async () => {
    const response = await getRequest(
      "services",
      { status: true, type: 1 },
      false
    );
    if (response) {
      setServicesList(response?.data);
    }
  };
  const GetSystems = async () => {
    const response = await getRequest(
      "services",
      { status: true, type: 2 },
      false
    );
    if (response) {
      setSystemList(response?.data);
    }
  };

  const tabsDataGalery = [
    {
      id: "tab1",
      label: "Videogalereya",
      content: [
        {
          id: 1,
          title: "Title2",
          description: "Description1",
          image: "/image_news.png",
        },
        {
          id: 2,
          title: "Title23",
          description: "Description13",
          image: "/image_news.png",
        },
      ],
    },
    {
      id: "tab2",
      label: "Fotogalereya",
      content: [
        {
          id: 1,
          title: "Title2",
          description: "Description1",
          image: "/image_news.png",
        },
        {
          id: 2,
          title: "Title23",
          description: "Description13",
          image: "/image_news.png",
        },
      ],
    },
  ];

  return (
    <>
      <header className="header-container">
        <Header />
        <Menu />
        <video className="video-background" autoPlay loop muted>
          <source src="../video/vide_bg.mp4" type="video/mp4" />
        </video>
        <div className="gradient-overlay"></div>

        {/* // statistics */}
        <div className="header-content">
          <div className={"container"}>
            <div className={"row"}>
              {statistikalar.map((item, index) => {
                return (
                  <div className={"col-md-3 relative"} key={index}>
                    <div className={"divider-top"}></div>
                    <h3>{item?.name_uz}</h3>
                    <h2>{item?.value}</h2>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </header>

      <div className={"container"}></div>

      <div className={"middle_bg mt-4"}>
        <div
          className={"container mx-auto lg:max-w-[80%] py-10"}
          data-aos="fade-up"
        >
          <div>
            <h3 className={"font-bold text-2xl"}>Interaktiv xizmatlar</h3>
          </div>

          <div className={"grid grid-cols-3 gap-6"} style={{ marginTop: 30 }}>
            {services.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className={
                      "flex items-center bg-white p-3 rounded-xl gap-4 border"
                    }
                  >
                    <div style={{ width: "10%" }}>
                      <div className={"main_icon_rounded text-white text-2xl"}>
                        <FiArrowUpCircle />
                      </div>
                    </div>
                    <div className={"rounded-xl font-bold"}>
                      {item?.name_uz}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={"container"} data-aos="fade-up">
        <Tabs />

        <div className={"mt-6"}>
          <img src={StrategyBanner} alt={""} />
        </div>
      </div>

      <div className={"bg_banner mt-10"}>
        <div className={"py-4 px-4"}>
          <div style={{ marginTop: 80 }} data-aos="fade-up">
            <h3
              className={
                "text-2xl text-center text-black font-bold text-uppercase"
              }
            >
              Universitet axborot tizimlari
            </h3>

            <div className={"container"}>
              <div className={"row"}>
                {systems.map((item, index) => {
                  return (
                    <div className={"col-md-3"}>
                      <div className={"mt-6 relative"}>
                        <div
                          className={
                            "p-6 bg-white rounded-xl gap-4 border info_number h-22"
                          }
                        >
                          <div className={"font-bold"}>{item?.name_uz}</div>
                          <div className={"mt-1"}>
                            <Link
                              className={
                                "text-blue-600 border-1 text-decoration-none p-1 rounded border-blue-500 hover:bg-blue-500 hover:text-white"
                              }
                            >
                              Tizimga kirish
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"middle_bg py-6"}>
        <div
          className={"container mx-auto px-4 lg:max-w-[80%] py-6"}
          data-aos="fade-up"
        >
          <h3 className={"font-bold text-2xl"}>Fakultet va Kafedralar</h3>

          <div className={"mt-6"} style={{ marginTop: 30 }}>
            <div className={"grid grid-cols-2 gap-6"}>
              {kafedralar.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={
                      "cafedra bg-white rounded-xl p-4 border flex justify-between items-center hover:bg-primary hover:text-white hover:cursor-pointer group transition-all duration-300"
                    }
                  >
                    <h4>{item.kafedra_name}</h4>
                    <div className={"text-gray-400 group-hover:text-white"}>
                      <LuSquareArrowUpRight size={25} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className={"container mx-auto px-4 lg:max-w-[80%] py-6"}
        style={{ marginTop: 30 }}
        data-aos="fade-up"
      >
        <h3 className={"font-bold text-2xl"}>Hamkorlarimiz</h3>
        <div className={"mt-6"} style={{ marginTop: 30 }}>
          <PartnersCarousel />
        </div>
      </div>

      <div
        className={"container mx-auto px-4 lg:max-w-[80%] py-4"}
        data-aos="fade-up"
      >
        <div className={"mt-6"}>
          <GaleryTabs tabs={tabsDataGalery} />
        </div>
      </div>

      <div className={"middle_bg py-6"}>
        <div
          className={"container mx-auto px-4 lg:max-w-[80%] py-4"}
          data-aos="fade-up"
        >
          <h3 className={"font-bold text-2xl"}>Foydali havolalar</h3>
          <div className={"mt-6"} style={{ marginTop: 30 }}>
            <Foydali />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HomePage;
