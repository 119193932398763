import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { CustomSelect, InputPlaceholder } from "components";
import { Editor } from "admin/components/Editor2";
import { addPageValidation } from "./Validation";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { useAddUpdatePage, useFetchMenus } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddUpdatePageForm = ({ open, setIsOpen, selectedItem }) => {
  const { actionPagePending, addUpdatePage } = useAddUpdatePage(
    () => setIsOpen(false),
    selectedItem
  );
  const { menus, menusLoading } = useFetchMenus();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Sahifa qo'shish (${i18n.language})`}
      size="xl"
    >
      <Formik
        enableReinitialize
        initialValues={{
          menu: selectedItem?.sub_menu_id
            ? String(selectedItem.sub_menu_id)
            : "",
          content:
            lang === "uz"
              ? selectedItem?.page_content
              : lang === "ru"
              ? selectedItem?.page_content_ru
              : lang === "en"
              ? selectedItem?.page_content_en
              : "",
        }}
        validationSchema={addPageValidation}
        onSubmit={(values) => {
          addUpdatePage(values);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3">
              <InputPlaceholder isLoading={menusLoading}>
                <CustomSelect
                  label="Turi"
                  placeholder="Tanlang"
                  error={errors.menu}
                  touched={touched.menu}
                  value={values.menu}
                  name="menu"
                  onChange={(value) => setFieldValue("menu", value)}
                  options={menus?.map((item) => ({
                    label: item.sub_title_uz,
                    value: String(item.sub_menu_id),
                  }))}
                />
              </InputPlaceholder>

              <div className={"col-span-2"}>
                <Label className={cn("flex justify-between items-center mb-2")}>
                  <span>Maqola matni</span>

                  {touched.content && errors.content ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.content}
                    </span>
                  ) : null}
                </Label>
                <Editor
                  content={values.content}
                  onChange={(content) => setFieldValue("content", content)}
                />
              </div>
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionPagePending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionPagePending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
