import { useState, useEffect } from "react";
import { useAnimate, stagger } from "framer-motion";
import { MenuToggle } from "./ToggleBtn";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { useFetchMenus } from "./Queries";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const { menus } = useFetchMenus();
  const { i18n } = useTranslation();

  const scope = useMenuAnimation(isOpen);
  return (
    <div ref={scope}>
      <nav className="menu fixed -top-2 right-0 bottom-0 z-50 max-w-[400px] w-full  bg-secondaryDark pt-16">
        <Accordion
          className="bg-white pb-6 pt-0 text-white  overflow-y-auto w-full h-full"
          type="single"
          collapsible
        >
          <AccordionItem
            key="bosh-sahifa"
            className="text-start p-4 bg-gray-200 border-b-0 mb-2 "
          >
            <Link to="/" className="text-black">
              Bosh sahifa
            </Link>
          </AccordionItem>

          {menus?.map((item) => {
            if (item.submenu.length) {
              return (
                <AccordionItem
                  value={`item-${item.main_menu_id}`}
                  key={item.main_menu_id}
                  className="px-4  border-b-0 mb-2 bg-gray-200"
                >
                  <AccordionTrigger className="font-interMedium hover:no-underline text-md text-black">
                    {i18n.language === "ru"
                      ? item.main_menu_ru
                      : i18n.language === "uz"
                      ? item.main_menu_uz
                      : item.main_menu_en}
                  </AccordionTrigger>

                  {item.submenu.length > 0 && (
                    <AccordionContent className="">
                      {item.submenu.map((sub) => {
                        return (
                          <Link
                            key={sub.submenu_id}
                            onClick={() => setIsOpen(false)}
                            to={`/${sub.submenu_slug}`}
                            className="block w-full  text-black/80"
                          >
                            {i18n.language === "uz"
                              ? sub.submenu_title_uz
                              : i18n.language === "ru"
                              ? sub.submenu_title_u
                              : sub.submenu_title_en}
                          </Link>
                        );
                      })}
                    </AccordionContent>
                  )}
                </AccordionItem>
              );
            } else {
              return (
                <AccordionItem
                  key={item.main_menu_id}
                  className="text-start p-4 bg-gray-200 border-b-0 mb-2 text-black"
                >
                  <Link to={`/${item.main_slug}`} className="text-black">
                    {i18n.language === "ru"
                      ? item.main_menu_ru
                      : i18n.language === "uz"
                      ? item.main_menu_uz
                      : item.main_menu_en}
                  </Link>
                </AccordionItem>
              );
            }
          })}

          <AccordionItem className="p-4 text-start bg-gray-200 border-b-0 mb-2 text-black">
            <Link to={`/qabul`} className="text-black">
              Qabul
            </Link>
          </AccordionItem>

          <AccordionItem className="text-start p-4 bg-gray-200 border-b-0 mb-2 ">
            <Link to={`/contact`} className="text-black">
              Bog'lanish
            </Link>
          </AccordionItem>
        </Accordion>
      </nav>
      <MenuToggle toggle={() => setIsOpen(!isOpen)} />
    </div>
  );
}

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          [
            "nav",
            { transform: "translateX(0%)" },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.6 },
          ],
          [
            ".li",
            { transform: "scale(1)", opacity: 1, filter: "blur(0px)" },
            {
              delay: stagger(0.05),
              at: "-0.1",
              ease: [0.08, 0.65, 0.53, 0.96],
              duration: 0.6,
            },
          ],
        ]
      : [
          [
            ".li",
            { transform: "scale(0.5)", opacity: 0, filter: "blur(10px)" },
            {
              delay: stagger(0.05, { from: "last" }),
              at: "<",
              ease: [0.08, 0.65, 0.53, 0.96],
              duration: 0.6,
            },
          ],
          ["nav", { transform: "translateX(100%)" }, { at: "-0.1" }],
        ];

    animate([
      [
        "path.top",
        { d: isOpen ? "M 3 16.5 L 17 2.5" : "M 2 2.5 L 20 2.5" },
        { at: "<" },
      ],
      ["path.middle", { opacity: isOpen ? 0 : 1 }, { at: "<" }],
      [
        "path.bottom",
        { d: isOpen ? "M 3 2.5 L 17 16.346" : "M 2 16.346 L 20 16.346" },
        { at: "<" },
      ],
      ...menuAnimations,
    ]);

    document.body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen, animate]);

  return scope;
}
