import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";

export const CustomTextarea = ({
  label,
  error,
  touched,
  name,
  children,
  className,
  inputClassName,
  labelClassName,
  value,
  ...props
}) => {
  return (
    <div className={cn(" w-full grid gap-2", className)}>
      {label ? (
        <Label
          className={cn("flex justify-between items-center", labelClassName)}
          htmlFor={name}
        >
          <span>{label}</span>

          {touched && error ? (
            <span className="font-normal text-xs text-red-500 leading-none">
              {error}
            </span>
          ) : null}
        </Label>
      ) : null}

      {children || (
        <Textarea
          id={name}
          {...props}
          className={cn("text-sm input", inputClassName)}
          value={value ?? ""}
        />
      )}
    </div>
  );
};
