import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "components/ui/dialog";
import { cn } from "lib/utils";

export function CustomModal({
  open,
  setOpen,
  title,
  children,
  className,
  description,
  size,
}) {
  return (
    <Dialog modal={true} open={open} onOpenChange={setOpen}>
      <DialogContent
        className={cn(
          `flex flex-col items-center justify-center bg-white `,
          className
        )}
        onInteractOutside={(e) => {
          e.preventDefault();
        }}
        size={size}
      >
        <DialogHeader className=" w-full">
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <div className="flex-1 w-full ">{children}</div>
      </DialogContent>
    </Dialog>
  );
}
