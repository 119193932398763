import * as React from "react";
import { format, parse } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";

export function DatePicker({
  className,
  label,
  error,
  touched,
  placeholder = "Sanani tanlang",
  onChange,
  value,
  ...props
}) {
  const parsedDate = value ? parse(value, "dd-MM-yyyy", new Date()) : null;

  return (
    <Popover>
      <div className="grid gap-2">
        <Label>{label}</Label>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn(
              "w-full justify-start text-left font-normal  rounded shadow-sm input py-2 h-9",
              !value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {value ? (
              format(parsedDate, "dd-MM-yyyy")
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
      </div>

      <PopoverContent className="w-auto p-0 bg-white">
        <Calendar
          mode="single"
          selected={parsedDate}
          onSelect={(date) => date && onChange(format(date, "dd-MM-yyyy"))}
          initialFocus
          className="bg-white"
        />
      </PopoverContent>
    </Popover>
  );
}
