import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData, updateData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddUpdateKafedra = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionKafedraPending, mutate: addUpdateKafedra } =
    useMutation({
      mutationFn: async (data) => {
        const sendingData = {
          kafedra_menu_id: 8,

          kafedra_name: selectedItem?.kafedra_name || "",
          kafedra_name_ru: selectedItem?.kafedra_name_ru || "",
          kafedra_name_en: selectedItem?.kafedra_name_en || "",

          kafedra_about: selectedItem?.kafedra_about || "",
          kafedra_about_ru: selectedItem?.kafedra_about_ru || "",
          kafedra_about_en: selectedItem?.kafedra_about_en || "",
        };

        if (lang === "uz") {
          sendingData.kafedra_about = data.content;
          sendingData.kafedra_name = data.title;
        } else if (lang === "ru") {
          sendingData.kafedra_about_ru = data.content;
          sendingData.kafedra_name_ru = data.title;
        } else if (lang === "en") {
          sendingData.kafedra_about_en = data.content;
          sendingData.kafedra_name_en = data.title;
        }

        if (selectedItem) {
          return updateData(
            `edit-kafedra/${selectedItem?.kafedra_id}`,
            sendingData,
            true
          );
        }
        return postData("create-kafedra", sendingData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["kafedralar"] });
        close();
      },
    });

  return { actionKafedraPending, addUpdateKafedra };
};
