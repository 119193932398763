import { useEffect, useState } from "react";

export const DisabledPeopleBox = () => {
  const [range, setRange] = useState(0);
  const [theme, setTheme] = useState("default");

  useEffect(() => {
    document.querySelector("html").style.fontSize = `${range + 100}%`;
  }, [range]);

  useEffect(() => {
    const html = document.querySelector("html");
    if (theme === "default") {
      html.style.filter = "none";
    } else if (theme === "grayscale") {
      html.style.filter = "grayscale(100%)";
    } else if (theme === "invert") {
      html.style.filter = "invert(100%)";
    }
  }, [theme]);

  return (
    <>
      <h3 className="text-[14px] mb-[8px]">Sayt ko&apos;rinishi</h3>

      <div className="flex gap-[14px] mb-[8px]">
        <div>
          <input
            id="default"
            type="radio"
            className="peer"
            name="color"
            hidden
          />
          <label
            htmlFor="default"
            className="flex items-center justify-center bg-[#263469] text-white
            w-[32px] h-[32px] rounded-full text-[20px] border cursor-pointer"
            data-toggle="tooltip"
            data-placement="top"
            title="Odatiy ko'rinish"
            onClick={() => setTheme("default")}
          >
            A
          </label>
        </div>
        <div>
          <input id="grayscale" type="radio" name="color" hidden />
          <label
            onClick={() => setTheme("grayscale")}
            data-toggle="tooltip"
            data-placement="top"
            title="Oq qora ko'rinish"
            htmlFor="grayscale"
            className="flex items-center justify-center w-[32px] h-[32px] rounded-full border text-[20px] cursor-pointer bg-[#a6a6a699]"
          >
            A
          </label>
        </div>
        <div>
          <input id="invert" type="radio" name="color" hidden />
          <label
            onClick={() => setTheme("invert")}
            data-toggle="tooltip"
            data-placement="top"
            title="Qorong'ulashgan ko'rinish"
            htmlFor="invert"
            className="flex items-center justify-center w-[32px] h-[32px] rounded-full border text-[20px] cursor-pointer bg-[#41404099]"
          >
            A
          </label>
        </div>
      </div>

      <div className="flex flex-col ">
        <label htmlFor="customRange1" className="text-[14px] mb-[8px]">
          Shrift o&apos;lchami
        </label>
        <input
          min="0"
          max="40"
          type="range"
          className="form-range "
          id="customRange1"
          value={range}
          onChange={(e) => setRange(Number(e.target.value))}
        />
      </div>
    </>
  );
};
