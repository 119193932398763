import React from "react";
import { get } from "object-path";
import filesize from "filesize";

import "./style.css";
import { toast } from "react-toastify";

const FileUpload = ({
  inputClassName = "",
  labelClassName = "",
  value,
  name,
  id,
  text = "Fayl yuklang",
  displayName,
  isMulti = true,
  showPreview,
  onChange,
  formats = [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".pdf",
    ".zip",
    ".rar",
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".svg",
  ],
  icon,
  disabled = false,
  children = null,
}) => {
  const handleChange = (file) => {
    if (
      formats.includes(
        ("." + file.name.split(".").pop()).toString().toLowerCase()
      )
    ) {
      onChange([...value, file]);
    } else {
      toast.error("Noto'g'ri fayl yuklandi");
    }
  };

  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    let dt = e.dataTransfer;
    let files = dt.files;
    handleChange(files[0]);
  };

  const handleRemove = (i) => {
    const filtered = value.filter((_, index) => i !== index);
    onChange(filtered);
  };

  return (
    <div>
      {isMulti || value?.length === 0 ? (
        <div className={"position-relative h-full"}>
          <input
            className={`uploader-input position-absolute min-h-[100px] h-full w-full top-0 bottom-20 start-0 cursor-pointer ${inputClassName}`}
            type="file"
            id={id || "file"}
            name={name}
            onChange={(e) => {
              const file = e.target.files[0];
              handleChange(file);
            }}
            accept={formats.join(",")}
            disabled={disabled}
          />
          <label
            className={
              !children
                ? `document-uploader file-uploader file-uploader-hover  w-full fs-18 ${labelClassName}`
                : labelClassName
            }
            htmlFor={id || "file"}
            onDragEnter={(e) => {
              preventDefaults(e);
              e.target.classList.add("active");
            }}
            onDragOver={(e) => {
              preventDefaults(e);
              e.target.classList.add("active");
            }}
            onDragLeave={(e) => {
              preventDefaults(e);
              e.target.classList.remove("active");
            }}
            onDrop={(e) => {
              preventDefaults(e);
              e.target.classList.remove("active");
              handleDrop(e);
            }}
          >
            {children ? (
              children
            ) : (
              <>
                <span className={"fs-24 me-2"}>{icon}</span>
                {text}
              </>
            )}
          </label>
        </div>
      ) : null}

      {showPreview
        ? value.length
          ? value.map((file, key) => (
              <div
                key={key}
                className={
                  "w-100 overflow-hidden file-tem d-flex align-items-center rounded-3 p-3 bg-white border shadow-sm"
                }
              >
                <div className={"w-85"}>
                  <div
                    title={
                      get(file, "name")
                        ? get(file, "name")
                        : get(file, displayName)
                    }
                    // className={"overflow-hidden w-100"}
                    className="text-truncate"
                    // style={{ maxWidth: "430px", whiteSpace: 'wrap' }}
                  >
                    {get(file, "name")
                      ? get(file, "name")
                      : get(file, displayName)}
                  </div>
                  {get(file, "size") ? (
                    <span className={"font-size-12 text-secondary"}>
                      {filesize(get(file, "size", 0))}
                    </span>
                  ) : null}
                </div>
                {!disabled ? (
                  <div className={"text-center"}>
                    <button
                      type={"button"}
                      title={"Faylni o'chirish"}
                      className={"btn text-danger2 fs-24"}
                      onClick={() => handleRemove(key)}
                    >
                      <i className={"fa fa-times-circle"} />
                    </button>
                  </div>
                ) : null}
              </div>
            ))
          : null
        : null}
    </div>
  );
};

FileUpload.defaultProps = {
  id: "",
  progressBar: false,
  showPreview: false,
  text: "Fayl yuklang",
  formats: [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".pdf",
    ".zip",
    ".rar",
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".svg",
  ],
  icon: <i className="fal fa-paperclip" />,
  onChange: () => {},
  isMulti: false,
  displayName: "name",
};

export default FileUpload;
