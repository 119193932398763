import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./i18n";
import Layout from "./components/Layout";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/DynamicPage/DynamicPage";
import ContactPage from "./pages/ContactPage";
import PageNotFound from "./pages/PageNotFound";
import DavlatRamzlari from "./pages/DavlatRamzlari";
import MurojaatPage from "./pages/MurojaatPage";
import Qabul from "./pages/Qabul";
import AdminIndex from "./admin/Index";
// import DashboardPage from "./admintools/Dashboard";
import Xodimlar from "./admin/Xodimlar/Xodimlar";
import Maqolalar from "./admin/Maqolalar/Maqolalar";
import Sahifalar from "./admin/Sahifalar/Sahifalar";
import Rahbariyat from "./admin/Rahbariyat/Rahbariyat";
import Kafedralar from "./admin/Kafedralar/Kafedralar";
import Hamkor from "./admin/Hamkorlar/Hamkorlar";
import Service from "./admin/Xizmatlar/Service";
import Havola from "./admin/Links/Links";
import { ProtectedRoute, LoginProtectedRoutes } from "./ProtectedRoute";
import AdminLayout from "./admin/Layout";
import Statistika from "./admin/Statistika";
import Tizimlar from "./admin/Tizimlar/Tizimlar";
import GalleryPage from "./admin/Gallery/GalleryPage";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route index element={<HomePage />} />

          <Route path="/" element={<Layout />}>
            <Route path="universitet/:slug" element={<AboutPage />} />
            <Route path="kampus/:slug" element={<AboutPage />} />
            <Route path="faoliyat/:slug" element={<AboutPage />} />
            <Route path="talabalarga/:slug" element={<AboutPage />} />
            <Route path="davlat-ramzlari/:slug" element={<DavlatRamzlari />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="murojaat" element={<MurojaatPage />} />
            <Route path="qabul" element={<Qabul />} />
          </Route>

          <Route
            path="login"
            element={
              <LoginProtectedRoutes>
                <AdminIndex />
              </LoginProtectedRoutes>
            }
          />
          <Route path="admin" element={<AdminLayout />}>
            <Route
              index
              element={
                //   <ProtectedRoute allowedRoles={["admin", "user"]}>
                //     <DashboardPage />
                //   </ProtectedRoute>
                <Navigate to="/admin/rahbariyat" replace />
              }
            />

            <Route
              path="xodimlar"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Xodimlar />
                </ProtectedRoute>
              }
            />
            <Route
              path="maqolalar"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Maqolalar />
                </ProtectedRoute>
              }
            />

            <Route
              path="sahifalar"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Sahifalar />
                </ProtectedRoute>
              }
            />

            <Route
              path="rahbariyat"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Rahbariyat />
                </ProtectedRoute>
              }
            />

            <Route
              path="kafedra"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Kafedralar />
                </ProtectedRoute>
              }
            />

            <Route
              path="hamkor"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Hamkor />
                </ProtectedRoute>
              }
            />

            <Route
              path="havola"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Havola />
                </ProtectedRoute>
              }
            />
            <Route
              path="statistika"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Statistika />
                </ProtectedRoute>
              }
            />

            <Route
              path="xizmatlar"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Service />
                </ProtectedRoute>
              }
            />

            <Route
              path="tizimlar"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <Tizimlar />
                </ProtectedRoute>
              }
            />

            <Route
              path="gallery"
              element={
                <ProtectedRoute allowedRoles={["admin", "user"]}>
                  <GalleryPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="unauthorized" element={<h2>Unauthorized Access</h2>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
