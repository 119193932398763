import { Outlet } from "react-router-dom";
import Header from "./HeaderMain";
import Footer from "./Footer";
import Menu from "features/Menu/MenuMain";

function Layout() {
  return (
    <div>
      <Header />

      <Menu />

      <div>
        <main className={"mt-8"}>
          <Outlet />
        </main>
      </div>

      <Footer />
    </div>
  );
}

export default Layout;
//import Logo from "../images/logo.png";
