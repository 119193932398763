import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData, updateData } from "utils";
import { useTranslation } from "react-i18next";
import { serialize } from "object-to-formdata";

export const useAddLink = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionLinkPending, mutate: addUpdateLink } = useMutation({
    mutationFn: (data) => {
      const sendingData = {
        image: [data.image],
        url: data.url,

        name_uz: selectedItem?.name_uz || "",
        name_ru: selectedItem?.name_ru || "",
        name_en: selectedItem?.name_en || "",
      };

      // Set the name value according to current language
      if (lang === "uz") {
        sendingData.name_uz = data.name;
      } else if (lang === "ru") {
        sendingData.name_ru = data.name;
      } else if (lang === "en") {
        sendingData.name_en = data.name;
      }

      const formData = serialize(sendingData, {
        indices: true,
        allowEmptyArrays: true,
      });

      if (selectedItem) {
        return updateData(`link/edit/${selectedItem?.id}`, formData, true);
      }

      return postData("link/create", formData, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["links"] });
      close();
    },
  });

  return { addUpdateLink, actionLinkPending };
};
