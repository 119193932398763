import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
// import { useState, useEffect } from "react";

export const useFetchPagesList = (currentPage) => {
  //   const [pagesList, setPagesList] = useState([]);
  //   const [paginationData, setPaginationData] = useState({
  //     total: 1,
  //     perPage: 0,
  //     from: 0,
  //   });
  const { data: pagesList, isLoading: pagesListLoading } = useQuery({
    queryKey: ["pages"],
    queryFn: () => getData(`page-post-list`),
  });

  //   useEffect(() => {
  //     if (data && !isError) {
  //       setPagesList(data.data);
  //       setPaginationData({
  //         perPage: data.per_page,
  //         total: data.total,
  //         from: data.from,
  //       });
  //     }
  //   }, [data, isError]);

  return { pagesList, pagesListLoading };
};

export const useDeletePage = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deletePagePending, mutate: deletePage } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`post/${itemId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pages"] });
      close();
    },
  });

  return { deletePage, deletePagePending };
};
