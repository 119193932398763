import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import { homePageRu, homePageUz, commonRu, commonUz } from "@/locales";

const language = localStorage.getItem("i18nextLng") || "uz";

const resources = {
  ru: {},
  uz: {},
};

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: "uz",
  ns: ["homePage"],
  // defaultNS: "loginPage",
  react: {
    useSuspense: false,
  },
});

export default i18n;
