import { getData, deleteData, postData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchSystems = () => {
  const { data: systems, isLoading: systemsLoading } = useQuery({
    queryKey: ["systems"],
    queryFn: () => getData(`services?type=${2}`),
  });
  return { systems, systemsLoading };
};

export const useDeleteTizim = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteTizimPending, mutate: deleteTizim } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`service/delete/${itemId}`, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systems"] });
      close();
    },
  });

  return { deleteTizim, deleteTizimPending };
};

export const useChangeStatus = () => {
  const queryClient = useQueryClient();
  const { isPending: changeStatusPending, mutate: changeStatus } = useMutation({
    mutationFn: (itemId) => {
      return postData(`service/change-status`, { id: itemId }, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["systems"] });
    },
  });

  return { changeStatus, changeStatusPending };
};
