import * as Yup from "yup";

export const addRahbariyatValidation = Yup.object().shape({
  full_name: Yup.string().required("Kiriting"),
  address: Yup.string().required("Kiriting"),
  occupation: Yup.string().required("Kiriting"),
  bio: Yup.string().required("Kiriting"),
  duty: Yup.string().required("Kiriting"),
  phone: Yup.string().required("Kiriting"),
  email: Yup.string().required("Kiriting"),
});
