import * as Yup from "yup";

export const addGalleryValidation = Yup.object().shape({
  type: Yup.string().required("Tanlang"),
  title: Yup.string().required("Kiriting"),
  desc: Yup.string().required("Kiriting"),
  status: Yup.string().required("Kiriting"),
  cover_img: Yup.string().required("Kiriting"),
  url: Yup.string().when("type", {
    is: "1",
    then: (schema) => schema.required("Kiriting"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
