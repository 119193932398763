import { useState } from "react";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import { useFetchKafedraList, useDeleteKafedra } from "./Queries";
import { AddUpdateKafedraForm } from "../features/Kafedralar/AddUpdateKafedraForm";
import { Button } from "components/ui/button";
import CustomModal from "admin/components/CustomModal";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

function KafedralarPage() {
  const { kafedraList, postsKafedraLoading } = useFetchKafedraList();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { deleteKafedra, deleteKafedraPending } = useDeleteKafedra(() =>
    setIsDeleteModalOpen(false)
  );

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const selectItemHandler = (itemId) => {
    const selectedItem = kafedraList.find((item) => item.kafedra_id === itemId);
    setCurrentItem(selectedItem);
  };

  return (
    <>
      {showModal && (
        <AddUpdateKafedraForm
          open={showModal}
          setIsOpen={setShowModal}
          selectedItem={currentItem}
        />
      )}

      <CustomModal
        show={isDeleteModalOpen}
        setShow={setIsDeleteModalOpen}
        title="Kafedrani o'chirish"
        size="md"
      >
        <p className="mb-3">
          Ushmu ma'lumotni o'chirishga ishonchingiz komilmi?
        </p>
        <div className="flex justify-end">
          <Button
            disabled={deleteKafedraPending}
            type="button"
            className="bg-blue-500 hover:bg-opacity-90 text-white"
            onClick={() => deleteKafedra(currentItem?.kafedra_id)}
          >
            {deleteKafedraPending ? (
              <Loader2 className=" animate-spin" />
            ) : null}
            O'chirish
          </Button>
        </div>
      </CustomModal>

      <div
        className={"d-flex flex-row align-items-center justify-content-between"}
      >
        <h1 className="text-2xl font-semibold">Kafedralar</h1>
        <Button
          className={"btn btn-sm btn-primary"}
          onClick={() => {
            setCurrentItem(null);
            setShowModal(true);
          }}
        >
          <FiPlus />
          Qo'shish
        </Button>
      </div>

      {/* table  */}
      <div className="table-responsive mt-4">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Kafedra nomi</th>
              <th width={100}>Amal</th>
            </tr>
          </thead>
          <tbody>
            {postsKafedraLoading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Yuklanmoqda...
                </td>
              </tr>
            ) : (
              kafedraList?.map((item, index) => {
                return (
                  <tr key={item.kafedra_id}>
                    <td>{index + 1}</td>
                    <td>
                      {lang === "uz"
                        ? item?.kafedra_name
                        : lang === "ru"
                        ? item?.kafedra_name_ru
                        : lang === "en"
                        ? item?.kafedra_name_en
                        : ""}
                    </td>

                    <td>
                      <div className={"d-flex gap-3"}>
                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-secondary"}
                          onClick={() => {
                            selectItemHandler(item.kafedra_id);
                            setShowModal(true);
                          }}
                        >
                          <FiEdit />
                        </button>

                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-danger"}
                          onClick={() => {
                            selectItemHandler(item.kafedra_id);
                            setIsDeleteModalOpen(true);
                          }}
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
            {!postsKafedraLoading && kafedraList?.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Ma'lumot topilmadi
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default KafedralarPage;
