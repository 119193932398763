import { useState } from "react";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import { useFetchHamkorList, useDeleteHamkor } from "./Queries";
import { AddUpdatePartnerForm } from "../features/Hamkorlar/AddUpdatePartnersForm";
import { Button } from "components/ui/button";
import CustomModal from "admin/components/CustomModal";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

function HamkorlarPage() {
  const { partnersList, partnersListLoading } = useFetchHamkorList();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { deleteHamkor, deleteHamkorPending } = useDeleteHamkor(() =>
    setIsDeleteModalOpen(false)
  );

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const selectItemHandler = (itemId) => {
    const selectedItem = partnersList.find((item) => item.id === itemId);
    setCurrentItem(selectedItem);
  };

  return (
    <>
      {showModal && (
        <AddUpdatePartnerForm
          open={showModal}
          setIsOpen={setShowModal}
          selectedItem={currentItem}
        />
      )}
      {
        <CustomModal
          show={isDeleteModalOpen}
          setShow={setIsDeleteModalOpen}
          title="Rahbariyat a'zosini o'chirish"
          size="md"
        >
          <p className="mb-3">
            Ushmu ma'lumotni o'chirishga ishonchingiz komilmi?
          </p>
          <div className="flex justify-end">
            <Button
              disabled={deleteHamkorPending}
              type="button"
              className="bg-blue-500 hover:bg-opacity-90 text-white"
              onClick={() => deleteHamkor(currentItem?.id)}
            >
              {deleteHamkorPending ? (
                <Loader2 className=" animate-spin" />
              ) : null}
              O'chirish
            </Button>
          </div>
        </CustomModal>
      }
      <div
        className={"d-flex flex-row align-items-center justify-content-between"}
      >
        <h1 className="text-2xl font-semibold">Rahbariyat</h1>
        <Button
          className={"btn btn-sm btn-primary"}
          onClick={() => {
            setCurrentItem(null);
            setShowModal(true);
          }}
        >
          <FiPlus />
          Qo'shish
        </Button>
      </div>

      {/* table  */}
      <div className="table-responsive mt-4">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Hamkor nomi</th>
              <th width={100}>Amal</th>
            </tr>
          </thead>
          <tbody>
            {partnersListLoading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Yuklanmoqda...
                </td>
              </tr>
            ) : (
              partnersList?.map((items, index) => {
                return (
                  <tr key={items.id}>
                    <td>{index + 1}</td>
                    <td>
                      {lang === "uz"
                        ? items?.name_uz
                        : lang === "ru"
                        ? items?.name_ru
                        : lang === "en"
                        ? items?.name_en
                        : ""}
                    </td>

                    <td>
                      <div className={"d-flex gap-3"}>
                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-secondary"}
                          onClick={() => {
                            selectItemHandler(items.id);
                            setShowModal(true);
                          }}
                        >
                          <FiEdit />
                        </button>

                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-danger"}
                          onClick={() => {
                            selectItemHandler(items.id);
                            setIsDeleteModalOpen(true);
                          }}
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
            {!partnersListLoading && partnersList?.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Ma'lumot topilmadi
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default HamkorlarPage;
