import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomInput from "../components/CustomInput";
import { useApiRequest } from "../hooks/useRequest";
import { useNavigate } from "react-router-dom";

function Login() {
  const { postRequest, loading } = useApiRequest();
  const navigate = useNavigate();

  const Validation = Yup.object().shape({
    email: Yup.string().required("Заполните поле!"),
    password: Yup.string().required("Заполните поле!"),
  });

  const LoginUser = async (values, setValues) => {
    const response = await postRequest("login", values, () =>
      setValues({ email: "", password: "" })
    );

    if (response) {
      navigate("/admin");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
        <h2 className="text-2xl font-semibold text-center mb-6">Login</h2>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Validation}
          onSubmit={(values, { setValues }) => {
            LoginUser(values, setValues);
          }}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form>
              <>
                <div className="mb-4">
                  <CustomInput
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    typeInput={"email"}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>
                <div className="mb-4">
                  <CustomInput
                    name="password"
                    label="Password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    typeInput={"password"}
                    error={errors.password}
                    touched={touched.password}
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Login"}
                </button>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
