import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBase64, postData, updateData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddUpdateRahbariyat = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionRahbariyatPending, mutate: addUpdateRahbariyat } =
    useMutation({
      mutationFn: async (data) => {
        let imageBase64 = data?.image;

        if (data?.image instanceof File) {
          imageBase64 = await getBase64(data.image);
        }

        const sendingData = {
          member_menu_id: 8,
          member_phone: data.phone,
          member_email: data.email,
          member_photo: imageBase64,

          member_name: selectedItem?.member_name || "",
          member_deputy_name: selectedItem?.member_deputy_name || "",
          member_address: selectedItem?.member_address || "",
          member_bio: selectedItem?.member_bio || "",
          member_function: selectedItem?.member_function || "",

          member_name_ru: selectedItem?.member_name_ru || "",
          member_deputy_name_ru: selectedItem?.member_deputy_name_ru || "",
          member_address_ru: selectedItem?.member_address_ru || "",
          member_bio_ru: selectedItem?.member_bio_ru || "",
          member_function_ru: selectedItem?.member_function_ru || "",

          member_name_en: selectedItem?.member_name_en || "",
          member_deputy_name_en: selectedItem?.member_deputy_name_en || "",
          member_address_en: selectedItem?.member_address_en || "",
          member_bio_en: selectedItem?.member_bio_en || "",
          member_function_en: selectedItem?.member_function_en || "",

          post_old_image: "",
          file_name: data.image.name,
          member_kafedra_id: 0,
        };

        if (lang === "uz") {
          sendingData.member_name = data.full_name;
          sendingData.member_deputy_name = data.occupation;
          sendingData.member_address = data.address;
          sendingData.member_bio = data.bio;
          sendingData.member_function = data.duty;
        } else if (lang === "ru") {
          sendingData.member_name_ru = data.full_name;
          sendingData.member_deputy_name_ru = data.occupation;
          sendingData.member_address_ru = data.address;
          sendingData.member_bio_ru = data.bio;
          sendingData.member_function_ru = data.duty;
        } else if (lang === "en") {
          sendingData.member_name_en = data.full_name;
          sendingData.member_deputy_name_en = data.occupation;
          sendingData.member_address_en = data.address;
          sendingData.member_bio_en = data.bio;
          sendingData.member_function_en = data.duty;
        }

        if (selectedItem) {
          return updateData(
            `edit-member/${selectedItem?.member_id}`,
            sendingData,
            true
          );
        }
        return postData("create-member", sendingData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rahbariyat"] });
        close();
      },
    });

  return {
    addUpdateRahbariyat,
    actionRahbariyatPending,
  };
};
