import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData, updateData } from "utils";
import { useTranslation } from "react-i18next";
import { serialize } from "object-to-formdata";

export const useAddGallery = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionGelleryPending, mutate: addUpdateGallery } =
    useMutation({
      mutationFn: (data) => {
        const sendingData = {
          type: data.type,
          image: data.cover_img,
          status: data.status,

          name_uz: selectedItem?.title_uz || "",
          name_ru: selectedItem?.title_ru || "",
          name_en: selectedItem?.title_en || "",
          desc_uz: "",
          desc_ru: "",
          desc_en: "",
        };

        if (data.type === "1") {
          sendingData.url = data.url;
        } else if (data.type === "2") {
          sendingData.images = data.images;
        }
        // Set the name value according to current language
        if (lang === "uz") {
          sendingData.name_uz = data.title;
          sendingData.desc_uz = data.desc;
        } else if (lang === "ru") {
          sendingData.name_ru = data.title;
          sendingData.desc_ru = data.desc;
        } else if (lang === "en") {
          sendingData.name_en = data.title;
          sendingData.desc_en = data.desc;
        }

        const formData = serialize(sendingData, {
          indices: true,
          allowEmptyArrays: true,
        });

        if (selectedItem) {
          return updateData(`gallery/edit/${selectedItem?.id}`, formData, true);
        }

        return postData("gallery/create", formData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["gallery"] });
        close();
      },
    });

  return { addUpdateGallery, actionGelleryPending };
};
