import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData, updateData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddTizim = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionTizimPending, mutate: addUpdateTizim } = useMutation(
    {
      mutationFn: (data) => {
        const sendingData = {
          name_uz: selectedItem?.name_uz || "",
          name_ru: selectedItem?.name_ru || "",
          name_en: selectedItem?.name_en || "",
          type: 2,
          status: true,
        };

        // Set the name value according to current language
        if (lang === "uz") {
          sendingData.name_uz = data.name;
        } else if (lang === "ru") {
          sendingData.name_ru = data.name;
        } else if (lang === "en") {
          sendingData.name_en = data.name;
        }

        if (selectedItem) {
          return updateData(
            `service/edit/${selectedItem?.id}`,
            sendingData,
            true
          );
        }

        return postData("service/create", sendingData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["systems"] });
        close();
      },
    }
  );

  return { addUpdateTizim, actionTizimPending };
};
