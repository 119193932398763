import Logo from "images/footer_logo.png";
import LogoBlack from "images/logo.png";
import { LuSquareArrowUpRight } from "react-icons/lu";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useFetchMenus } from "./Queries";
import { useTranslation } from "react-i18next";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "components/ui/menubar";
import { ChevronDown } from "lucide-react";
import { cn } from "lib/utils";

function LogoMenuSection() {
  const { i18n } = useTranslation();
  const { menus } = useFetchMenus();
  const location = useLocation();

  return (
    <div className={"container pt-6"}>
      <div className="flex xl:flex-row flex-col xl:items-center xl:justify-between  gap-5 mb-6">
        <div className="flex  items-center xl:justify-start justify-between gap-5 xl:max-w-[60%] ">
          <Link to="/" className=" max-w-[250px] hidden sm:block">
            <img
              src={location.pathname === "/" ? Logo : LogoBlack}
              alt="Logo"
              className="object-cover h-full w-full"
            />
          </Link>

          <div className=" ">
            <p
              className={cn(
                "italic border-l-4 border-gray-300 pl-4 my-2 text-base leading-relaxed",
                location.pathname === "/" ? "text-white" : "text-black "
              )}
            >
              “Agar mendan sizni nima qiynaydi?” deb so‘rasangiz,
              farzandlarimizning ta’lim va tarbiyasi deb javob beraman.
            </p>
            <p className={"flex justify-end px-6 "}>Sh.Mirziyoyev</p>
          </div>
        </div>

        <div className="flex  items-center gap-6">
          <Link
            to={`/murojaat`}
            className={cn(
              "text-decoration-none border-2 py-2 px-5 rounded-xl flex items-center gap-3",
              location.pathname === "/" ? "text-white" : "text-black"
            )}
          >
            <span
              className={cn(
                location.pathname === "/" ? "text-white" : "text-black"
              )}
            >
              Virtual qabulxona
            </span>
            <span className="text-white">
              <LuSquareArrowUpRight size={25} />
            </span>
          </Link>

          <div className="xl:block hidden">
            <p>Ishonch telefonlari</p>
            <p className="font-bold">+99870-717-17-76</p>
          </div>
        </div>
      </div>

      <Menubar className="lg:flex justify-center hidden gap-4 bg-transparent border-none shadow-none  flex-1 ">
        <NavLink
          to="/"
          className={cn(
            "text-base  hover:text-[#3F63F6] font-medium",
            location.pathname === "/" ? "text-white" : "text-[#6b7280]"
          )}
        >
          Bosh sahifa
        </NavLink>
        {menus?.map((item) => {
          if (item.submenu.length > 0) {
            return (
              <MenubarMenu key={item.main_menu_id}>
                <MenubarTrigger
                  className={cn(
                    "flex gap-2  font-medium text-base text-center bg-transparent cursor-pointer rounded p-0 h-auto hover:bg-transparent hover:text-[#3F63F6] focus:bg-transparent focus:text-[#3F63F6] data-[active]:bg-transparent data-[state=open]:bg-transparent data-[state=open]:text-[#3F63F6] ",
                    location.pathname === "/" ? "text-white" : "text-[#6b7280]"
                  )}
                >
                  {i18n.language === "ru"
                    ? item.main_menu_ru
                    : i18n.language === "uz"
                    ? item.main_menu_uz
                    : item.main_menu_en}
                  {item.submenu.length > 0 && <ChevronDown size={16} />}
                </MenubarTrigger>
                {item.submenu.length > 0 && (
                  <MenubarContent className="bg-white">
                    {item.submenu.map((sub) => {
                      return (
                        <MenubarItem
                          key={sub.submenu_id}
                          className="focus:bg-[#3F63F6] focus:bg-opacity-30 border-b"
                        >
                          <NavLink
                            to={`/${sub.submenu_slug}`}
                            className={cn(
                              "block w-full  hover:text-[#3F63F6] "
                            )}
                          >
                            {i18n.language === "uz"
                              ? sub.submenu_title_uz
                              : i18n.language === "ru"
                              ? sub.submenu_title_u
                              : sub.submenu_title_en}
                          </NavLink>
                        </MenubarItem>
                      );
                    })}
                  </MenubarContent>
                )}
              </MenubarMenu>
            );
          } else {
            return (
              <NavLink
                key={item.main_menu_id}
                to={`/${item.main_slug}`}
                className={cn(
                  "text-base  hover:text-[#3F63F6] font-medium",
                  location.pathname === "/" ? "text-white" : "text-[#6b7280]"
                )}
              >
                {i18n.language === "ru"
                  ? item.main_menu_ru
                  : i18n.language === "uz"
                  ? item.main_menu_uz
                  : item.main_menu_en}
              </NavLink>
            );
          }
        })}

        <NavLink
          to={`/qabul`}
          className={cn(
            "text-base  hover:text-[#3F63F6] font-medium",
            location.pathname === "/" ? "text-white" : "text-[#6b7280]"
          )}
        >
          Qabul
        </NavLink>
        <NavLink
          to={`/contact`}
          className={cn(
            "text-base  hover:text-[#3F63F6] font-medium",
            location.pathname === "/" ? "text-white" : "text-[#6b7280]"
          )}
        >
          Bog'lanish
        </NavLink>
      </Menubar>
    </div>
  );
}

export default LogoMenuSection;
