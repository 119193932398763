import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchHamkorList = () => {
  const { data: partnersList, isLoading: partnersListLoading } = useQuery({
    queryKey: ["partners"],
    queryFn: () => getData("partners"),
  });
  return { partnersList, partnersListLoading };
};

export const useDeleteHamkor = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteHamkorPending, mutate: deleteHamkor } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`partner/delete/${itemId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["partners"] });
      close();
    },
  });

  return { deleteHamkor, deleteHamkorPending };
};
