import {
  RiFacebookCircleFill,
  RiInstagramFill,
  RiSmartphoneLine,
  RiYoutubeFill,
} from "react-icons/ri";
import { BsEyeglasses } from "react-icons/bs";
import React from "react";
import Flag from "../images/flag.png";
import Gerb from "../images/gerb.png";
import Melody from "../images/melody.png";
import { NavLink, Link } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { DisabledPeopleBox } from "features/DisabledPeopleBox";
import { LangSelect } from "features/LangSelect";
import { MobileMenu } from "features/Menu/MobileMenu";

function Header() {
  function openWin() {
    window.open(
      window.location.href,
      "_blank",
      "toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=no, copyhistory=yes, width=390, height=600"
    );
  }

  return (
    <header className="bg-main text-white py-2">
      <div className="container">
        <div className="flex justify-between items-center">
          {/* Birinchi ustun: ijtimoiy tarmoqlar va email */}

          <div className=" items-center gap-3 hidden lg:flex">
            <Link
              target="_blank"
              to="https://instagram.com"
              className="text-white"
            >
              <RiInstagramFill size={26} />
            </Link>
            <Link
              target="_blank"
              to="https://facebook.com"
              className="text-white"
            >
              <RiFacebookCircleFill size={26} />
            </Link>
            <Link
              target="_blank"
              to="https://youtube.com"
              className="text-white"
            >
              <RiYoutubeFill size={26} />
            </Link>
            <span className="xl:block hidden">
              Elektron pochta: <Link to="email:info@jtsu.uz">info@jtsu.uz</Link>
            </span>
          </div>

          {/* Ikkinchi ustun: davlat ramzlari va qo'shimcha sahifalar */}

          <ul className="list-unstyled flex gap-3 mb-0">
            <li className="d-flex gap-3">
              <NavLink to="/davlat-ramzlari/bayroq">
                <img src={Flag} alt="" style={{ width: 25 }} />
              </NavLink>
              <NavLink to="/davlat-ramzlari/gerb">
                <img src={Gerb} alt="" style={{ width: 26 }} />
              </NavLink>
              <NavLink to="/davlat-ramzlari/madhiya">
                <img src={Melody} alt="" style={{ width: 25 }} />
              </NavLink>
            </li>

            <li className="mx-2 hidden sm:block">
              <Link
                to="#harakatlar-strategiyasi"
                className="text-white text-decoration-none"
              >
                Harakatlar strategiyasi
              </Link>
            </li>
            <li className="hidden sm:block">
              <Link
                to="#sayt-xaritasi"
                className="text-white text-decoration-none"
              >
                Sayt xaritasi
              </Link>
            </li>
          </ul>

          {/* Uchinchi ustun: tugmalar */}

          <div className="flex gap-3 relative">
            <button
              onClick={openWin}
              className="btn btn-link text-white hidden lg:block"
            >
              <RiSmartphoneLine size={26} />
            </button>

            <Popover>
              <PopoverTrigger className="" asChild>
                <button className="btn btn-link text-white">
                  <BsEyeglasses size={26} />
                </button>
              </PopoverTrigger>
              <PopoverContent className="w-[200px] p-3">
                <DisabledPeopleBox />
              </PopoverContent>
            </Popover>

            <LangSelect />

            <div className="lg:hidden block bg-red-400 ms-3">
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
