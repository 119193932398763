import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { CustomInput2, CustomFileInput } from "components";
import { addPartnerValidation } from "./Validation";
import { useAddUpdateHamkor } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddUpdatePartnerForm = ({ open, setIsOpen, selectedItem }) => {
  const { addUpdateHamkor, actionHamkorPending } = useAddUpdateHamkor(
    () => setIsOpen(false),
    selectedItem
  );
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Hamkor qo'shish (${i18n.language})`}
      size="md"
    >
      <Formik
        enableReinitialize
        initialValues={{
          image: selectedItem?.image || "",
          name:
            lang === "uz"
              ? selectedItem?.name_uz
              : lang === "ru"
              ? selectedItem?.name_ru
              : lang === "en"
              ? selectedItem?.name_en
              : "",
        }}
        validationSchema={addPartnerValidation}
        onSubmit={(values) => {
          addUpdateHamkor(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid  gap-3">
              <div>
                <CustomFileInput
                  label="Rasm"
                  touched={touched.image}
                  error={errors.image}
                  name="image"
                  placeholder="Tanlang"
                  value={values.image}
                  onChange={(image) => {
                    setFieldValue("image", image);
                  }}
                />
              </div>
              <CustomInput2
                placeholder="Kiriting"
                label={"Hamkor nomi"}
                name={"name"}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
                touched={touched.name}
              />
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionHamkorPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionHamkorPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
