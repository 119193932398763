import React from "react";
import { Modal } from "react-bootstrap";

function CustomModal({
  show,
  setShow,
  title,
  children,
  fullscreen = false,
  size = "lg",
}) {
  return (
    <Modal
      show={show}
      size={size}
      onHide={() => setShow(false)}
      fullscreen={fullscreen}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto max-h-screen">{children}</Modal.Body>
    </Modal>
  );
}

export default CustomModal;
