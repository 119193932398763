import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { postData, updateData, getData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddUpdatePage = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionPagePending, mutate: addUpdatePage } = useMutation({
    mutationFn: async (data) => {
      const sendingData = {
        page_menu_id: data.menu,
        page_content: selectedItem?.page_content || "",
        page_content_ru: selectedItem?.page_content_ru || "",
        page_content_en: selectedItem?.page_content_en || "",
      };

      if (lang === "uz") {
        sendingData.page_content = data.content;
      } else if (lang === "ru") {
        sendingData.page_content_ru = data.content;
      } else if (lang === "en") {
        sendingData.page_content_en = data.content;
      }

      if (selectedItem) {
        return updateData(
          `edit-page-post/${selectedItem?.page_id}`,
          sendingData,
          true
        );
      }
      return postData("create-page-post", sendingData, true);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["pages"] });
      close();
    },
  });

  return { actionPagePending, addUpdatePage };
};

export const useFetchMenus = () => {
  const { data: menus, isLoading: menusLoading } = useQuery({
    queryKey: ["menus"],
    queryFn: () => getData("sub-menu-type/1"),
  });

  return { menus, menusLoading };
};
