import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchRahbariyatList = () => {
  const { data: rahbariyatList, isLoading: rahbariyatListLoading } = useQuery({
    queryKey: ["rahbariyat"],
    queryFn: () => getData("member-list/8"),
  });
  return { rahbariyatList, rahbariyatListLoading };
};

export const useDeleteRahbariyat = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteRahbariyatPending, mutate: deleteRahbariyat } =
    useMutation({
      mutationFn: (itemId) => {
        return deleteData(`delete-member/${itemId}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rahbariyat"] });
        close();
      },
    });

  return { deleteRahbariyat, deleteRahbariyatPending };
};
