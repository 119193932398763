import { Form, Formik } from "formik";
import {
  CustomInput2,
  CustomModal,
  CustomSelect,
  CustomTextarea,
  CustomFileInput,
} from "components";
import { addGalleryValidation } from "./Validation";
import { useAddGallery } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";
import { Switch } from "components/ui/switch";
import { Label } from "components/ui/label";
import { FileUp, X } from "lucide-react";
import { cn } from "lib/utils";
import { useState } from "react";

export const AddUpdateGalleryForm = ({ open, setIsOpen, selectedItem }) => {
  const { addUpdateGallery, actionGalleryPending } = useAddGallery(
    () => setIsOpen(false),
    selectedItem
  );
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const [images, setImages] = useState([]);

  return (
    <CustomModal
      open={open}
      setOpen={setIsOpen}
      title={`Gallery qo'shish (${i18n.language})`}
      className="min-w-[900px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          title:
            lang === "uz"
              ? selectedItem?.name_uz
              : lang === "ru"
              ? selectedItem?.name_ru
              : lang === "en"
              ? selectedItem?.name_en
              : "",
          desc: "",
          type: "",
          cover_img: "",
          status: true,
          url: "",
        }}
        validationSchema={addGalleryValidation}
        onSubmit={(values) => {
          addUpdateGallery({ ...values, images });
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3">
              <CustomSelect
                label="Turi"
                name="type"
                error={errors.type}
                touched={touched.type}
                value={values.type}
                onChange={(value) => setFieldValue("type", value)}
                options={[
                  { value: "2", label: "Foto" },
                  { value: "1", label: "Video" },
                ]}
                placeholder="Tanlang"
              />
              <CustomInput2
                placeholder="Kiriting"
                label={"Sarlavha"}
                name={"title"}
                value={values.title}
                onChange={handleChange}
                error={errors.title}
                touched={touched.title}
              />

              <div className="col-span-2">
                <CustomTextarea
                  label="Ta'rif"
                  name="desc"
                  error={errors.desc}
                  touched={touched.desc}
                  value={values.desc}
                  onChange={handleChange}
                  className="h-[120px]"
                  placeholder="Bu yerga yozing..."
                />
              </div>

              <div className="grid gap-2">
                <Label
                  className="flex justify-between items-center"
                  htmlFor="switch"
                >
                  <span>Chop etish</span>

                  {touched.status && errors.status ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.status}
                    </span>
                  ) : null}
                </Label>
                <Switch
                  checked={values.status}
                  className="data-[state=checked]:bg-blue-500 data-[state=unchecked]:bg-blue-200"
                  onCheckedChange={(value) => setFieldValue("status", value)}
                />
              </div>

              <CustomFileInput
                label="Muqova rasm"
                name="cover_img"
                error={errors.cover_img}
                touched={touched.cover_img}
                placeholder="Tanlang"
                value={values.cover_img}
                onChange={(file) => setFieldValue("cover_img", file)}
              />

              {values.type === "2" && (
                <div className="grid gap-2 col-span-2">
                  <Label className="flex justify-between" htmlFor="images">
                    <span>Rasmlar</span>
                  </Label>
                  <label
                    className={cn(
                      "flex items-center justify-between h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-base shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm"
                    )}
                  >
                    {images.length} ta rasm tanlandi
                    <FileUp className="w-5 h-5" />
                    <input
                      hidden
                      multiple
                      type="file"
                      name="images"
                      id="images"
                      onChange={(e) => {
                        const files = e.target.files;

                        if (files) {
                          for (let i = 0; i < files.length; i++) {
                            setImages((images) => [...images, files[i]]);
                          }
                        }
                      }}
                    />
                  </label>

                  <div className="grid grid-cols-3 gap-2 max-h-[320px] overflow-y-auto">
                    {images &&
                      images.map((image, index) => {
                        const url = URL.createObjectURL(image);
                        return (
                          <div
                            key={index}
                            className="flex min-h-[150px] items-center relative"
                          >
                            <img
                              src={url}
                              alt="images"
                              className="w-full h-full object-cover rounded-md"
                            />
                            <button
                              type="button"
                              className="absolute top-0 right-0 h-5 w-5 flex items-center justify-center rounded-full bg-red-100 text-red-500"
                              onClick={() =>
                                setImages((images) =>
                                  images.filter((_, i) => i !== index)
                                )
                              }
                            >
                              <X size={14} />
                            </button>
                          </div>
                        );
                      })}

                    {selectedItem &&
                      selectedItem?.images.length > 0 &&
                      selectedItem?.images.map((item) => (
                        <div
                          key={item.id}
                          className="flex min-h-[150px] items-center relative"
                        >
                          <img
                            src={`${import.meta.env.VITE_API_FILE_URL}/${
                              item.image
                            }`}
                            alt="images"
                            className="w-full h-full object-cover rounded-md"
                          />
                          <button
                            // disabled={deleteFotoLoading}
                            type="button"
                            className="absolute top-0 right-0 h-5 w-5 flex items-center justify-center rounded-full bg-red-100 text-red-500"
                            // onClick={() =>
                            //   deleteFoto({
                            //     id: selectedfotobank.id,
                            //     photoId: item.id,
                            //   })
                            // }
                          >
                            {/* {deleteFotoLoading && deletedData?.id === item.id ? (
                          <Loader2 size={14} className="animate-spin" />
                        ) : ( */}
                            <X size={14} />
                            {/* )} */}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {values.type === "1" && (
                <CustomInput2
                  name="url"
                  label="Video havolasi"
                  error={errors.url}
                  touched={touched.url}
                  onChange={handleChange}
                  value={values.url}
                  placeholder="Kiriting"
                />
              )}
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionGalleryPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionGalleryPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
