import { useQuery } from "@tanstack/react-query";
import { getData } from "utils";

export const useFetchMenus = () => {
  const { data: menus } = useQuery({
    queryKey: ["menus"],
    queryFn: () => {
      return getData("menu");
    },
  });

  return { menus };
};
