import { Form, Formik } from "formik";
import {
  CustomInput2,
  CustomTextarea,
  CustomFileInput,
  CustomSelect,
  InputPlaceholder,
  DatePicker,
  CustomModal,
} from "components";
import { Editor } from "admin/components/Editor2";
import { format } from "date-fns";
import { addMaqolaValidation } from "./Validation";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { useAddUpdateMaqola, useFetchPostTypesList } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddMaqolaForm = ({ open, setIsOpen, selectedItem }) => {
  const { actionMaqolaPending, addUpdateMaqola } = useAddUpdateMaqola(
    () => setIsOpen(false),
    selectedItem
  );
  const { types, typesLoading } = useFetchPostTypesList();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      open={open}
      setOpen={setIsOpen}
      title={`Maqola qo'shish (${i18n.language})`}
      className="min-w-[900px]"
    >
      <Formik
        enableReinitialize
        initialValues={{
          type: selectedItem?.post_menu_id
            ? String(selectedItem?.post_menu_id)
            : "",
          date: selectedItem?.post_date
            ? format(new Date(selectedItem.post_date), "dd-MM-yyyy")
            : "",
          image: selectedItem?.post_image || "",
          title:
            lang === "uz"
              ? selectedItem?.post_title
              : lang === "ru"
              ? selectedItem?.post_title_ru
              : lang === "en"
              ? selectedItem?.post_title_en
              : "",
          desc:
            lang === "uz"
              ? selectedItem?.post_desc
              : lang === "ru"
              ? selectedItem?.post_desc_ru
              : lang === "en"
              ? selectedItem?.post_desc_en
              : "",

          content:
            lang === "uz"
              ? selectedItem?.post_content
              : lang === "ru"
              ? selectedItem?.post_content_ru
              : lang === "en"
              ? selectedItem?.post_content_en
              : "",
        }}
        validationSchema={addMaqolaValidation}
        onSubmit={(values) => {
          addUpdateMaqola(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3">
              <InputPlaceholder isLoading={typesLoading}>
                <CustomSelect
                  label="Turi"
                  placeholder="Tanlang"
                  error={errors.type}
                  touched={touched.type}
                  value={values.type}
                  name="type"
                  onChange={(value) => setFieldValue("type", value)}
                  options={types?.map((item) => ({
                    label: item.sub_title_uz,
                    value: String(item.sub_menu_id),
                  }))}
                />
              </InputPlaceholder>

              <CustomInput2
                placeholder="Kiriting"
                label={"Maqola nomi"}
                name={"title"}
                value={values.title}
                onChange={handleChange}
                error={errors.title}
                touched={touched.title}
              />

              <DatePicker
                label="Maqola chiqish sanasi"
                error={errors.date}
                touched={touched.date}
                value={values.date}
                onChange={(date) => setFieldValue("date", date)}
              />

              <div>
                <CustomFileInput
                  label="Rasm"
                  touched={touched.image}
                  error={errors.image}
                  name="image"
                  placeholder="Tanlang"
                  value={values.image}
                  onChange={(image) => {
                    setFieldValue("image", image);
                  }}
                />
              </div>

              <div className="col-span-2">
                <CustomTextarea
                  placeholder={"Kiriting"}
                  label={"Maqola tasnifi"}
                  name={"desc"}
                  value={values.desc}
                  onChange={handleChange}
                  error={errors.desc}
                  touched={touched.desc}
                  inputClassName="h-[100px]"
                />
              </div>

              <div className={"col-span-2"}>
                <Label className={cn("flex justify-between items-center mb-2")}>
                  <span>Maqola matni</span>

                  {touched.content && errors.content ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.content}
                    </span>
                  ) : null}
                </Label>
                <Editor
                  content={values.content}
                  onChange={(content) => setFieldValue("content", content)}
                />
              </div>
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionMaqolaPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionMaqolaPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
