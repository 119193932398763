import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="container flex flex-col justify-center items-center h-screen">
      <h1 className="text-6xl"> 404</h1>
      <p>Sahifa topilmadi</p>
      <Link to="/" className="text-blue-500">
        Bosh sahifaga qaytish
      </Link>
    </div>
  );
}

export default PageNotFound;
