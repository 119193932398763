import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { PatternFormat } from "react-number-format";
import { CustomInput2, CustomTextarea, CustomFileInput } from "components";
// import Editor from "admin/components/Editor";
import { Editor } from "admin/components/Editor2";
import { addRahbariyatValidation } from "./Validation";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { useAddUpdateRahbariyat } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddRahbariyatForm = ({ open, setIsOpen, selectedItem }) => {
  const { addUpdateRahbariyat, actionRahbariyatPending } =
    useAddUpdateRahbariyat(() => setIsOpen(false), selectedItem);
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Rahbar qo'shish (${i18n.language})`}
      size="xl"
    >
      <Formik
        enableReinitialize
        initialValues={{
          image: selectedItem?.member_photo || "",
          full_name:
            lang === "uz"
              ? selectedItem?.member_name
              : lang === "ru"
              ? selectedItem?.member_name_ru
              : lang === "en"
              ? selectedItem?.member_name_en
              : "",
          address:
            lang === "uz"
              ? selectedItem?.member_address
              : lang === "ru"
              ? selectedItem?.member_address_ru
              : lang === "en"
              ? selectedItem?.member_address_en
              : "",
          phone: selectedItem?.member_phone || "",
          email: selectedItem?.member_email || "",
          occupation:
            lang === "uz"
              ? selectedItem?.member_deputy_name
              : lang === "ru"
              ? selectedItem?.member_deputy_name_ru
              : lang === "en"
              ? selectedItem?.member_deputy_name_en
              : "",
          bio:
            lang === "uz"
              ? selectedItem?.member_bio
              : lang === "ru"
              ? selectedItem?.member_bio_ru
              : lang === "en"
              ? selectedItem?.member_bio_en
              : "",
          duty:
            lang === "uz"
              ? selectedItem?.member_function
              : lang === "ru"
              ? selectedItem?.member_function_ru
              : lang === "en"
              ? selectedItem?.member_function_en
              : "",
        }}
        validationSchema={addRahbariyatValidation}
        onSubmit={(values) => {
          addUpdateRahbariyat(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3">
              <CustomInput2
                placeholder="Xodim FIO"
                label={"Xodim FIO"}
                name={"full_name"}
                value={values.full_name}
                onChange={handleChange}
                error={errors.full_name}
                touched={touched.full_name}
              />

              <CustomInput2
                placeholder={"Manzil"}
                label={"Manzil"}
                name={"address"}
                value={values.address}
                onChange={handleChange}
                error={errors.address}
                touched={touched.address}
              />

              <CustomInput2
                label="Telefon"
                name="phone"
                error={errors.phone}
                touched={touched.phone}
              >
                <PatternFormat
                  className="input"
                  format="+998 (##) ###-##-##"
                  mask="_"
                  value={values.phone}
                  onValueChange={({ value }) => {
                    setFieldValue("phone", value);
                  }}
                  placeholder="+998 (__) ___-__-__"
                  id="phone"
                  name="phone"
                />
              </CustomInput2>

              <CustomInput2
                label="Email"
                name="email"
                error={errors.email}
                touched={touched.email}
                placeholder="Kiriting"
                onChange={handleChange}
                value={values.email}
              />

              <CustomTextarea
                placeholder={"Xodim lavozimi"}
                label={"Xodim lavozimi"}
                name={"occupation"}
                value={values.occupation}
                onChange={handleChange}
                error={errors.occupation}
                touched={touched.occupation}
              />

              <div>
                <CustomFileInput
                  label="Rasm"
                  touched={touched.image}
                  error={errors.image}
                  name="image"
                  placeholder="Tanlang"
                  value={values.image}
                  onChange={(image) => {
                    setFieldValue("image", image);
                  }}
                />
              </div>

              <div className={"col-span-2"}>
                <Label className={cn("flex justify-between items-center mb-2")}>
                  <span>Biografiya</span>

                  {touched.bio && errors.bio ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.bio}
                    </span>
                  ) : null}
                </Label>
                <Editor
                  content={values.bio}
                  onChange={(content) => setFieldValue("bio", content)}
                />
              </div>

              <div className={"col-span-2"}>
                <Label className={cn("flex justify-between items-center mb-2")}>
                  <span>Funksiyasi</span>

                  {touched.duty && errors.duty ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.duty}
                    </span>
                  ) : null}
                </Label>

                <Editor
                  content={values.duty}
                  onChange={(content) => setFieldValue("duty", content)}
                />
              </div>
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionRahbariyatPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionRahbariyatPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
