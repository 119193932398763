import HttpService from "./axiosService";
import { toast } from "react-toastify";
import { redirect } from "react-router-dom";
import { AxiosError } from "axios";

const handleResponse = (response, withToast) => {
  const { code, message, data } = response.data;

  if (code === 401) {
    return redirect("/");
  } else if (code === 200 || code === 201) {
    if (withToast) {
      toast.success(message);
    }
    return data;
  } else {
    toast.warning(message);
  }
};

const handleError = (error) => {
  let errorMessage = "Xatolik yuz berdi!";
  // check if error is instance of AxiosError
  if (error instanceof AxiosError) {
    errorMessage = error.response?.data.message || error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }
  toast.error(errorMessage);
};

export const postData = async (slug, data, withToast = false) => {
  try {
    if (!data || !slug) {
      throw new Error(`Malumotlar yetarli emas!`);
    }
    const response = await HttpService.post(slug, data);
    return handleResponse(response, withToast);
  } catch (error) {
    handleError(error);
  }
};

export const getData = async (slug, withToast = false) => {
  try {
    if (!slug) {
      throw new Error(`Malumotlar yetarli emas!`);
    }
    const response = await HttpService.get(slug);
    return handleResponse(response, withToast);
  } catch (error) {
    return handleError(error);
  }
};

export const updateData = async (slug, data, withToast = false) => {
  try {
    if (!data || !slug) {
      throw new Error(`Malumotlar yetarli emas!`);
    }
    const response = await HttpService.put(slug, data);
    return handleResponse(response, withToast);
  } catch (error) {
    return handleError(error);
  }
};

export const patchData = async (slug, data, withToast = false) => {
  try {
    if (!data || !slug) {
      throw new Error(`Malumotlar yetarli emas!`);
    }
    const response = await HttpService.patch(slug, data);
    return handleResponse(response, withToast);
  } catch (error) {
    return handleError(error);
  }
};

export const deleteData = async (slug, withToast = false) => {
  try {
    if (!slug) {
      throw new Error(`Malumotlar yetarli emas!`);
    }
    const response = await HttpService.delete(slug);
    return handleResponse(response, withToast);
  } catch (error) {
    return handleError(error);
  }
};
