import { Loader2 } from "lucide-react";

export const InputPlaceholder = ({ isLoading, children }) => {
  return (
    <div className="flex items-center justify-center w-full">
      {isLoading ? (
        <Loader2 className="text-primaryBlue animate-spin" />
      ) : (
        children
      )}
    </div>
  );
};
