import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchLinks = () => {
  const { data: links, isLoading: linksLoading } = useQuery({
    queryKey: ["links"],
    queryFn: () => getData("links"),
  });
  return { links, linksLoading };
};

export const useDeleteLink = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteLinkPending, mutate: deleteLink } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`link/delete/${itemId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["links"] });
      close();
    },
  });

  return { deleteLink, deleteLinkPending };
};
