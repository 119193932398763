// const response = await postRequest("create-post", values);
// const response = await putRequest(`edit-post/${postOne?.post_id}`, values);
// const response = await deleteRequest("post/"+id+"");/
import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect } from "react";

export const useFetchPostsList = (currentPage) => {
  const [postsList, setPostsList] = useState([]);
  const [paginationData, setPaginationData] = useState({
    total: 1,
    perPage: 0,
    from: 0,
  });
  const {
    data,
    isLoading: postsListLoading,
    isError,
  } = useQuery({
    queryKey: ["posts"],
    queryFn: () => getData(`post-list/${currentPage}`),
  });

  useEffect(() => {
    if (data && !isError) {
      setPostsList(data.data);
      setPaginationData({
        perPage: data.per_page,
        total: data.total,
        from: data.from,
      });
    }
  }, [data, isError]);

  return { postsList, postsListLoading, paginationData };
};

export const useDeleteMaqola = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteMaqolaPending, mutate: deleteMaqola } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`post/${itemId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      close();
    },
  });

  return { deleteMaqola, deleteMaqolaPending };
};
