import { useState } from "react";
import { FiEdit, FiPlus, FiTrash2 } from "react-icons/fi";
import { useFetchSystems, useDeleteTizim, useChangeStatus } from "./Queries";
import { Switch } from "components/ui/switch";
import { AddUpdateTizimForm } from "../features/Tizimlar/AddUpdateTizimlarForm";
import { Button } from "components/ui/button";
import CustomModal from "admin/components/CustomModal";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

function TizimlarPage() {
  const { systems, systemsLoading } = useFetchSystems();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { deleteTizim, deleteTizimPending } = useDeleteTizim(() =>
    setIsDeleteModalOpen(false)
  );
  const { changeStatus } = useChangeStatus();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const selectItemHandler = (itemId) => {
    const selectedItem = systems.find((item) => item.id === itemId);
    setCurrentItem(selectedItem);
  };

  return (
    <>
      {showModal && (
        <AddUpdateTizimForm
          open={showModal}
          setIsOpen={setShowModal}
          selectedItem={currentItem}
        />
      )}
      {
        <CustomModal
          show={isDeleteModalOpen}
          setShow={setIsDeleteModalOpen}
          title="Rahbariyat a'zosini o'chirish"
          size="md"
        >
          <p className="mb-3">
            Ushmu ma'lumotni o'chirishga ishonchingiz komilmi?
          </p>
          <div className="flex justify-end">
            <Button
              disabled={deleteTizimPending}
              type="button"
              className="bg-blue-500 hover:bg-opacity-90 text-white"
              onClick={() => deleteTizim(currentItem?.id)}
            >
              {deleteTizimPending ? (
                <Loader2 className=" animate-spin" />
              ) : null}
              O'chirish
            </Button>
          </div>
        </CustomModal>
      }
      <div
        className={"d-flex flex-row align-items-center justify-content-between"}
      >
        <h1 className="text-2xl font-semibold">Rahbariyat</h1>
        <Button
          className={"btn btn-sm btn-primary"}
          onClick={() => {
            setCurrentItem(null);
            setShowModal(true);
          }}
        >
          <FiPlus />
          Qo'shish
        </Button>
      </div>

      {/* table  */}
      <div className="table-responsive mt-4">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>№</th>
              <th>Nomi</th>
              <th>Qiymat</th>
              <th>Status</th>
              <th width={100}>Amal</th>
            </tr>
          </thead>
          <tbody>
            {systemsLoading ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Yuklanmoqda...
                </td>
              </tr>
            ) : (
              systems?.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>
                      {lang === "uz"
                        ? item?.name_uz
                        : lang === "ru"
                        ? item?.name_ru
                        : lang === "en"
                        ? item?.name_en
                        : ""}
                    </td>
                    <td>{item.value}</td>
                    <td>
                      <Switch
                        checked={item.status || false}
                        className="data-[state=checked]:bg-blue-500 data-[state=unchecked]:bg-blue-200"
                        onCheckedChange={() => changeStatus(item.id)}
                      />
                    </td>
                    <td>
                      <div className={"d-flex gap-3"}>
                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-secondary"}
                          onClick={() => {
                            selectItemHandler(item.id);
                            setShowModal(true);
                          }}
                        >
                          <FiEdit />
                        </button>

                        <button
                          type="button"
                          className={"btn btn-sm btn-outline-danger"}
                          onClick={() => {
                            selectItemHandler(item.id);
                            setIsDeleteModalOpen(true);
                          }}
                        >
                          <FiTrash2 />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
            {!systemsLoading && systems?.length === 0 ? (
              <tr>
                <td colSpan={5} className="text-center">
                  Ma'lumot topilmadi
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TizimlarPage;
