import { NavLink } from "react-router-dom";

function Sidebar() {
  return (
    <div className="w-64 h-screen bg-white border-r border-r-gray-200 p-3 fixed">
      <h2 className="text-xl font-semibold px-4">Dashboard</h2>
      <nav className="space-y-2 mt-3">
        {[
          { path: "/admin/rahbariyat", label: "Rahbariyat" },
          { path: "/admin/xodimlar", label: "Xodimlar" },
          { path: "/admin/maqolalar", label: "Maqolalar" },
          { path: "/admin/sahifalar", label: "Sahifalar" },
          { path: "/admin/kafedra", label: "Kafedralar" },
          { path: "/admin/hamkor", label: "Hamkorlar" },
          { path: "/admin/havola", label: "Foydali havolalar" },
          { path: "/admin/statistika", label: "Statistika" },
          { path: "/admin/xizmatlar", label: "Interaktiv xizmatlar" },
          { path: "/admin/tizimlar", label: "Axborot tizimlari" },
          { path: "/admin/gallery", label: "Gallareya" },
        ].map((item) => (
          <NavLink
            key={item.path}
            to={item.path}
            className={({ isActive }) =>
              `text-gray-600 block py-2 px-4 text-decoration-none rounded hover:bg-gray-700 hover:text-white active:bg-gray-700 focus:text-white ${
                isActive ? "bg-gray-700 text-white" : ""
              }`
            }
          >
            {item.label}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}

export default Sidebar;
