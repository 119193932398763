import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postData, updateData } from "utils";
import { useTranslation } from "react-i18next";

export const useAddUpdateHamkor = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionHamkorPending, mutate: addUpdateHamkor } =
    useMutation({
      mutationFn: (data) => {
        const sendingData = {
          name_uz: selectedItem?.name_uz || "",
          name_ru: selectedItem?.name_ru || "",
          name_en: selectedItem?.name_en || "",
        };

        // Set the name value according to current language
        if (lang === "uz") {
          sendingData.name_uz = data.name;
        } else if (lang === "ru") {
          sendingData.name_ru = data.name;
        } else if (lang === "en") {
          sendingData.name_en = data.name;
        }

        const formData = new FormData();
        formData.append("name_uz", sendingData.name_uz);
        formData.append("name_ru", sendingData.name_ru);
        formData.append("name_en", sendingData.name_en);

        if (data.image instanceof File) {
          formData.append("image", data.image);
        }

        if (selectedItem) {
          return updateData(`partner/edit/${selectedItem?.id}`, formData, true);
        }

        return postData("partner/create", formData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["partners"] });
        close();
      },
    });

  return {
    addUpdateHamkor,
    actionHamkorPending,
  };
};
