"use client";

import { useState } from "react";
import NewsImg from '../images/elon_1.png';



export default function Tabs({ tabs }) {
    const [activeTab, setActiveTab] = useState(tabs[0]?.id || "");

    return (
        <div className="w-full mt-10">
            {/* Tabs Header */}
            <div className="border-b border-gray-300 flex">
                {tabs.map((tab) => (
                    <button
                        key={tab.id}
                        onClick={() => setActiveTab(tab.id)}
                        className={`w-1/4 py-2 text-2xl text-gray-600 text-left font-semibold hover:text-gray-500 transition-colors ${
                            activeTab === tab.id
                                ? "border-b-2 border-blue-500 text-black"
                                : ""
                        }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Tabs Content */}
            <div className="mt-4">
                {tabs.map(
                    (tab) =>
                        activeTab === tab.id && (
                            <div key={tab.id}>
                                {/* Agar content array bo'lsa */}
                                {Array.isArray(tab.content) ? (
                                    <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                        {tab.content.map((item) => (
                                            <li key={item.id}
                                                className="p-4 rounded-lg hover:shadow-lg transition-shadow">
                                                <div>
                                                    <img
                                                        src={require(`../images${item.image}`)}
                                                        alt={"s"}/>
                                                </div>
                                                <h3 className="text-lg font-semibold text-gray-800 mt-2">
                                                    {item.title}
                                                </h3>
                                                <p className="text-gray-600">
                                                    {item.description}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    // Agar content obyekt bo'lsa
                                    <div className={'bg-white'}>
                                        <div>
                                            <img src={NewsImg} alt={"s"}/>
                                        </div>
                                        <h3 className="text-lg font-semibold text-gray-800">
                                            {tab.content.title}
                                        </h3>
                                        <p className="text-gray-600">
                                            {tab.content.description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )
                )}
            </div>
        </div>
    );
}
