import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
// import ruFlag from "@/assets/icons/ru.svg";
// import uzFlag from "@/assets/icons/oz.svg";
import { useTranslation } from "react-i18next";
import { cn } from "lib/utils";

export const LangSelect = ({ className }) => {
  const { i18n } = useTranslation();

  return (
    <Select
      defaultValue={i18n.language}
      onValueChange={(value) => {
        i18n.changeLanguage(value);
        localStorage.setItem("i18nextLng", value);
      }}
    >
      <SelectTrigger
        className={cn(
          "p-0 border-0 h-auto text-white w-auto gap-2 focus:ring-0 shadow-none me-6",
          className
        )}
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="bg-white">
        <SelectItem value="uz">
          <div className="flex items-center gap-2">
            {/* <img src={uzFlag} alt="" /> */}
            <span>O'zbek</span>
          </div>
        </SelectItem>
        <SelectItem value="ru">
          <div className="flex items-center gap-2">
            {/* <img src={ruFlag} alt="" /> */}
            <span>Русский</span>
          </div>
        </SelectItem>

        <SelectItem value="en">
          <div className="flex items-center gap-2">
            {/* <img src={ruFlag} alt="" /> */}
            <span>English</span>
          </div>
        </SelectItem>
      </SelectContent>
    </Select>
  );
};
