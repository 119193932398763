import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { CustomInput2 } from "components";
import { Editor } from "admin/components/Editor2";
import { addKafedraValidation } from "./Validation";
import { Label } from "components/ui/label";
import { cn } from "lib/utils";
import { useAddUpdateKafedra } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddUpdateKafedraForm = ({ open, setIsOpen, selectedItem }) => {
  const { actionKafedraPending, addUpdateKafedra } = useAddUpdateKafedra(
    () => setIsOpen(false),
    selectedItem
  );

  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Kafedra qo'shish (${i18n.language})`}
      size="xl"
    >
      <Formik
        enableReinitialize
        initialValues={{
          title:
            lang === "uz"
              ? selectedItem?.kafedra_name
              : lang === "ru"
              ? selectedItem?.kafedra_name_ru
              : lang === "en"
              ? selectedItem?.kafedra_name_en
              : "",

          content:
            lang === "uz"
              ? selectedItem?.kafedra_about
              : lang === "ru"
              ? selectedItem?.kafedra_about_ru
              : lang === "en"
              ? selectedItem?.kafedra_about_en
              : "",
        }}
        validationSchema={addKafedraValidation}
        onSubmit={(values) => {
          addUpdateKafedra(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3">
              <CustomInput2
                placeholder="Kiriting"
                label={"Kafedra nomi"}
                name={"title"}
                value={values.title}
                onChange={handleChange}
                error={errors.title}
                touched={touched.title}
              />

              <div className={"col-span-2"}>
                <Label className={cn("flex justify-between items-center mb-2")}>
                  <span>Kafedra haqida</span>

                  {touched.content && errors.content ? (
                    <span className="font-normal text-xs text-red-500 leading-none">
                      {errors.content}
                    </span>
                  ) : null}
                </Label>
                <Editor
                  content={values.content}
                  onChange={(content) => setFieldValue("content", content)}
                />
              </div>
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionKafedraPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionKafedraPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
