import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { CustomInput2 } from "components";
import { addTizimValidation } from "./Validation";
import { useAddTizim } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddUpdateTizimForm = ({ open, setIsOpen, selectedItem }) => {
  const { addUpdateTizim, actionTizimPending } = useAddTizim(
    () => setIsOpen(false),
    selectedItem
  );
  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Tizim qo'shish (${i18n.language})`}
      size="md"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name:
            lang === "uz"
              ? selectedItem?.name_uz
              : lang === "ru"
              ? selectedItem?.name_ru
              : lang === "en"
              ? selectedItem?.name_en
              : "",
        }}
        validationSchema={addTizimValidation}
        onSubmit={(values) => {
          addUpdateTizim(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid  gap-3">
              <CustomInput2
                placeholder="Kiriting"
                label={"Hamkor nomi"}
                name={"name"}
                value={values.name}
                onChange={handleChange}
                error={errors.name}
                touched={touched.name}
              />
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionTizimPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionTizimPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
