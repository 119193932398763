import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { useTranslation } from "react-i18next";
import { Button } from "components/ui/button";

const options = [
  {
    label: "O'zbekcha",
    value: "uz",
  },
  {
    label: "Ruscha",
    value: "ru",
  },
  {
    label: "Inglizcha",
    value: "en",
  },
];

function Navbar() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const LogOut = useCallback(() => {
    dispatch(logout(null));
    navigate("/login");
  }, [dispatch, navigate]);

  return (
    <div className="bg-white text-white p-3 fixed top-0 left-64 right-0 flex justify-between items-center border-b">
      <h1 className="text-xl font-semibold">Dashboard Header</h1>

      <div className="flex itmes-center gap-2">
        <Select
          className=""
          onValueChange={(value) => {
            i18n.changeLanguage(value);
            localStorage.setItem("i18nextLng", value);
          }}
          defaultValue={localStorage.getItem("i18nextLng") || "uz"}
        >
          <SelectTrigger className="text-black h-auto">
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="bg-white">
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button
          variant="outline"
          className=" text-red-600  rounded  border-red-500  hover:border-red-400 hover:bg-red-100"
          onClick={() => LogOut()}
        >
          Chiqish
        </Button>
      </div>
    </div>
  );
}

export default Navbar;
