import CustomModal from "admin/components/CustomModal";
import { Form, Formik } from "formik";
import { PatternFormat } from "react-number-format";
import {
  CustomInput2,
  CustomTextarea,
  CustomFileInput,
  CustomSelect,
} from "components";
import { addXodimValidation } from "./Validation";
import { useAddUpdateXodim, useFetchKafedraList } from "./Queries";
import { Loader2 } from "lucide-react";
import { Button } from "components/ui/button";
import { useTranslation } from "react-i18next";

export const AddXodimForm = ({ open, setIsOpen, selectedItem }) => {
  const { addUpdateXodim, actionXodimPending } = useAddUpdateXodim(
    () => setIsOpen(false),
    selectedItem
  );
  const { kafedraList, kafedraListLoading } = useFetchKafedraList();

  const { i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <CustomModal
      show={open}
      setShow={setIsOpen}
      title={`Xodim qo'shish (${i18n.language})`}
      size="xl"
    >
      <Formik
        enableReinitialize
        initialValues={{
          category: selectedItem?.kafedra_id
            ? String(selectedItem?.kafedra_id)
            : "",
          image: selectedItem?.member_photo || "",
          full_name:
            lang === "uz"
              ? selectedItem?.member_name
              : lang === "ru"
              ? selectedItem?.member_name_ru
              : lang === "en"
              ? selectedItem?.member_name_en
              : "",
          address:
            lang === "uz"
              ? selectedItem?.member_address
              : lang === "ru"
              ? selectedItem?.member_address_ru
              : lang === "en"
              ? selectedItem?.member_address_en
              : "",
          phone: selectedItem?.member_phone || "",
          email: selectedItem?.member_email || "",
          occupation:
            lang === "uz"
              ? selectedItem?.member_deputy_name
              : lang === "ru"
              ? selectedItem?.member_deputy_name_ru
              : lang === "en"
              ? selectedItem?.member_deputy_name_en
              : "",
        }}
        validationSchema={addXodimValidation}
        onSubmit={(values) => {
          addUpdateXodim(values);
        }}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-2 gap-3 mb-2">
              {!kafedraListLoading && (
                <CustomSelect
                  label="Kategoriya"
                  name="category"
                  touched={touched.category}
                  error={errors.category}
                  value={values.category}
                  options={kafedraList?.map((item) => ({
                    label: item.kafedra_name,
                    value: String(item.kafedra_id),
                  }))}
                  placeholder="Tanlang"
                  onChange={(value) => setFieldValue("category", value)}
                />
              )}

              <CustomInput2
                placeholder="Xodim FIO"
                label={"Xodim FIO"}
                name={"full_name"}
                value={values.full_name}
                onChange={handleChange}
                error={errors.full_name}
                touched={touched.full_name}
              />

              <CustomInput2
                placeholder={"Manzil"}
                label={"Manzil"}
                name={"address"}
                value={values.address}
                onChange={handleChange}
                error={errors.address}
                touched={touched.address}
              />

              <CustomInput2
                label="Telefon"
                name="phone"
                error={errors.phone}
                touched={touched.phone}
              >
                <PatternFormat
                  className="input"
                  format="+998 (##) ###-##-##"
                  mask="_"
                  value={values.phone}
                  onValueChange={({ value }) => {
                    setFieldValue("phone", value);
                  }}
                  placeholder="+998 (__) ___-__-__"
                  id="phone"
                  name="phone"
                />
              </CustomInput2>

              <CustomInput2
                label="Email"
                name="email"
                error={errors.email}
                touched={touched.email}
                placeholder="Kiriting"
                onChange={handleChange}
                value={values.email}
              />
              <div>
                <CustomFileInput
                  label="Rasm"
                  touched={touched.image}
                  error={errors.image}
                  name="image"
                  placeholder="Tanlang"
                  value={values.image}
                  onChange={(image) => {
                    setFieldValue("image", image);
                  }}
                />
              </div>

              <div className="col-span-2">
                <CustomTextarea
                  placeholder={"Xodim lavozimi"}
                  label={"Xodim lavozimi"}
                  name={"occupation"}
                  value={values.occupation}
                  onChange={handleChange}
                  error={errors.occupation}
                  touched={touched.occupation}
                  inputClassName="h-[100px]"
                />
              </div>
            </div>

            <div className={"py-2 d-flex align-items-end justify-end"}>
              <Button
                disabled={actionXodimPending}
                type="submit"
                className={"text-white bg-blue-500 hover:bg-opacity-90"}
              >
                {actionXodimPending ? (
                  <Loader2 className="animate-spin" />
                ) : null}
                Saqlash
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};
