import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { login, logout } from "../redux/authSlice";

const API_BASE_URL = "https://api.uzdjtsu.uz/api";
// const API_BASE_URL = "http://127.0.0.1:8000/api";

export function useApiRequest() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const postRequest = async (
    slug,
    data,
    resetForm = null,
    showToast = true
  ) => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_BASE_URL}/${slug}`, data, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (slug === "login") {
        dispatch(login(response.data?.data));
      }

      if (resetForm) resetForm();

      if (showToast) {
        toast.success(response?.data?.message || "Muvaffaqiyatli yuborildi!", {
          position: "top-right",
        });
      }

      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout(null));
        toast.error("Session tugadi, iltimos qayta login qiling!", {
          position: "top-right",
        });
      } else {
        toast.error(error.response?.data?.message || "Xatolik yuz berdi!", {
          position: "top-right",
        });
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getRequest = async (slug, params = {}, isAuth = true) => {
    try {
      setLoading(true);
      if (!token && isAuth) {
        dispatch(logout(null));
        toast.error("Sessiya tugadi, iltimos qayta login qiling!", {
          position: "top-right",
        });
        return null;
      }

      const response = await axios.get(`${API_BASE_URL}/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      return response.data;
    } catch (error) {
      if (error.response?.status === 401 && isAuth) {
        dispatch(logout(null));
        toast.error("Session tugadi, iltimos qayta login qiling!", {
          position: "top-right",
        });
      } else {
        toast.error(error.response?.data?.message || "Xatolik yuz berdi!", {
          position: "top-right",
        });
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  const putRequest = async (slug, data) => {
    try {
      setLoading(true);
      const response = await axios.put(`${API_BASE_URL}/${slug}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(response?.data?.message || "Ma'lumot yangilandi!", {
        position: "top-right",
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout(null));
        toast.error("Session tugadi, iltimos qayta login qiling!", {
          position: "top-right",
        });
      } else {
        toast.error(error.response?.data?.message || "Xatolik yuz berdi!", {
          position: "top-right",
        });
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  const deleteRequest = async (slug, params = {}) => {
    try {
      setLoading(true);
      const response = await axios.delete(`${API_BASE_URL}/${slug}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      toast.success(response?.data?.message || "Ma'lumot o‘chirildi!", {
        position: "top-right",
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        dispatch(logout(null));
        toast.error("Session tugadi, iltimos qayta login qiling!", {
          position: "top-right",
        });
      } else {
        toast.error(error.response?.data?.message || "Xatolik yuz berdi!", {
          position: "top-right",
        });
      }
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { postRequest, getRequest, putRequest, deleteRequest, loading };
}
