import { getData, updateData, getBase64, postData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

export const useFetchKafedraList = () => {
  const { data: kafedraList, isLoading: kafedraListLoading } = useQuery({
    queryKey: ["kafedra-list"],
    queryFn: () => getData("list-kafedra"),
  });

  return { kafedraList, kafedraListLoading };
};

export const useAddUpdateXodim = (close, selectedItem) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const queryClient = useQueryClient();

  const { isPending: actionXodimPending, mutate: addUpdateXodim } = useMutation(
    {
      mutationFn: async (data) => {
        let imageBase64 = data?.image;

        if (data?.image instanceof File) {
          imageBase64 = await getBase64(data.image);
        }

        const sendingData = {
          member_menu_id: 44,
          member_phone: data.phone,
          member_email: data.email,
          member_photo: imageBase64,
          member_kafedra_id: +data.category,

          member_name: selectedItem?.member_name || "",
          member_deputy_name: selectedItem?.member_deputy_name || "",
          member_address: selectedItem?.member_address || "",

          member_name_ru: selectedItem?.member_name_ru || "",
          member_deputy_name_ru: selectedItem?.member_deputy_name_ru || "",
          member_address_ru: selectedItem?.member_address_ru || "",

          member_name_en: selectedItem?.member_name_en || "",
          member_deputy_name_en: selectedItem?.member_deputy_name_en || "",
          member_address_en: selectedItem?.member_address_en || "",
        };

        if (lang === "uz") {
          sendingData.member_name = data.full_name;
          sendingData.member_deputy_name = data.occupation;
          sendingData.member_address = data.address;
        } else if (lang === "ru") {
          sendingData.member_name_ru = data.full_name;
          sendingData.member_deputy_name_ru = data.occupation;
          sendingData.member_address_ru = data.address;
        } else if (lang === "en") {
          sendingData.member_name_en = data.full_name;
          sendingData.member_deputy_name_en = data.occupation;
          sendingData.member_address_en = data.address;
        }
        console.log(sendingData);
        if (selectedItem) {
          return updateData(
            `edit-member/${selectedItem?.member_id}`,
            sendingData,
            true
          );
        }
        return postData("create-member", sendingData, true);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["rahbariyat"] });
        close();
      },
    }
  );

  return { addUpdateXodim, actionXodimPending };
};
