import { getData, deleteData } from "utils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const useFetchRahbariyatList = () => {
  const { data: xodimList, isLoading: xodimListLoading } = useQuery({
    queryKey: ["xodimlar"],
    queryFn: () => getData("member-list/44"),
  });
  return { xodimList, xodimListLoading };
};

export const useDeleteXodim = (close) => {
  const queryClient = useQueryClient();
  const { isPending: deleteXodimPending, mutate: deleteXodim } = useMutation({
    mutationFn: (itemId) => {
      return deleteData(`delete-member/${itemId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["xodimlar"] });
      close();
    },
  });

  return { deleteXodim, deleteXodimPending };
};
